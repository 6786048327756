// Entry point for the build script in your package.json

// require("channels");

import Rails from "@rails/ujs";
// Rails.start();
import * as ActiveStorage from "@rails/activestorage";
ActiveStorage.start();
import "./jquery";
import "./toastr";
import "bootstrap";
import "@client-side-validations/client-side-validations";
import "./controllers"
import "@hotwired/turbo-rails"
import "./custom/smooth_scroll"

// Add Google Analytics tracking for Turbo Drive navigation
document.addEventListener("turbo:load", function() {
  if (typeof gtag === "function") {
    gtag("config", "UA-110953725-2", {
      page_location: window.location.href,
      page_path: window.location.pathname + window.location.search
    });
  }
});
