import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "state", "city", "service", "submit"];

  connect() {
    this.stateTarget.addEventListener('change', this.stateChanged.bind(this));
    this.cityTarget.addEventListener('change', this.cityChanged.bind(this));
    this.setNewFormState();
  }

  stateChanged(event) {
    const state = event.target.value;
    this.resetCityAndService();  // Always reset dependent fields
    if (state) {
      this.fetchCities(state);
    }
    this.setNewFormState();
  }

  cityChanged(event) {
    const city = event.target.value;
    if (city) {
      this.fetchServices(city);
    } else {
      this.resetService();
    }
  }

  async fetchCities(state) {
    const response = await fetch(`/pages/cities?state=${state}`);
    const cities = await response.json();
    this.updateCityOptions(cities);
  }

  async fetchServices(city) {
    const state = this.stateTarget.value;
    const response = await fetch(`/pages/services?city=${city}&state=${state}`);
    const services = await response.json();
    this.updateServiceOptions(services);
  }

  updateCityOptions(cities) {
    this.cityTarget.innerHTML = '<option value="">Select City..</option>';
    this.cityTarget.innerHTML += '<option value="All">All</option>';
    cities.forEach(city => {
      if (city !== "All") {
        const option = document.createElement('option');
        option.value = city;
        option.textContent = city;
        this.cityTarget.appendChild(option);
      }
    });
    this.cityTarget.disabled = false;
  }

  updateServiceOptions(services) {
    this.serviceTarget.innerHTML = '<option value="">Select Service..</option>';
    services.forEach(service => {
      const option = document.createElement('option');
      option.value = service;
      option.textContent = service;
      this.serviceTarget.appendChild(option);
    });
    this.serviceTarget.disabled = false;
  }

  resetCityAndService() {
    this.cityTarget.innerHTML = '<option value="">Select City..</option>';
    this.cityTarget.disabled = true;
    this.resetService();
  }

  resetService() {
    this.serviceTarget.innerHTML = '<option value="">Select Service..</option>';
    this.serviceTarget.disabled = true;
  }

  setNewFormState() {
    const state = this.stateTarget.value;

    if (!state || state === "Select State..") {
      console.log('hit disable block');
      this.cityTarget.disabled = true;
      this.serviceTarget.disabled = true;
      this.submitTarget.disabled = true;
    } else {
      this.cityTarget.disabled = false;
      this.serviceTarget.disabled = false;
      this.submitTarget.disabled = false;
    }
  }

  submit() {
    this.element.requestSubmit();
  }

  final(e) {
    e.preventDefault();
    if (!window.location.pathname.includes('/search')) {
      this.formTarget.action = "/search";
    }
    this.formTarget.submit();
  }
}
